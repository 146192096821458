import ConfirmationModal from "@components/ConfirmationModal";
import { LOCALSTORAGE_KEYS } from "@config";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { getErrors, getWorkflowKeywordsQuery } from "@screens/workflow/queries";
import useMonacoContext, {
  MonacoProvider,
} from "@screens/workflow/studio/MonacoContext";
import useImportNodeWithInput from "@screens/workflow/studio/hooks/useImportNodeWithInput";
import { useQueryClient } from "@tanstack/react-query";
import { notify } from "@utils/utils";
import clsx from "clsx";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import ReactFlow, {
  Background,
  Connection,
  Edge,
  EdgeChange,
  MiniMap,
  Node,
  NodeChange,
  NodeMouseHandler,
  OnConnectEnd,
  OnConnectStartParams,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  useReactFlow,
} from "reactflow";
import "reactflow/dist/style.css";
import { analyticsInstance } from "src/config/event-analytics";
import { PolicyStudioActions } from "src/constants/EventAnalytics";
import {
  AccessDenied,
  AccessDeniedErrorMessage,
} from "src/screens/AccessErrorDenied";
import { useWorkflowContext } from "../WorkflowContext";
import WfChangeLog from "../components/ChangeLog";
import { WorkflowInfo } from "../components/WorkflowInfo";
import CustomControls from "./components/CustomControls";
import Sidebar from "./components/Sidebar";
import { WorkflowStudioTopbar } from "./components/workflow-studio-topbar";
import edgeTypes from "./edge";
import useAddNode from "./hooks/useAddNode";
import nodeTypes from "./node-types";
import {
  getAddConnection,
  getRemoveConnection,
  getRemoveNode,
  isValidConnection,
} from "./utils";
import { WfWarning } from "./validations";
import withSWtoReactFlow from "./withSWtoReactFlow";

type WorkflowStudioProps = {
  nodes: Node[];
  edges: Edge[];
  warnings: WfWarning[];
  setNodes: setStateType<Node[]>;
  setEdges: setStateType<Edge[]>;
  autoArrange: () => Promise<void>;
};

const nodeClassName = (node: Node) => {
  switch (node.type) {
    case "ruleNode":
      return "fill-orange-50 stroke-orange-600 w-[200px] h-[100px]";
    case "policyNode":
      return "fill-purple-50 stroke-purple-600 w-[200px] h-[100px]";
    case "endNode":
      return "fill-red-50 stroke-red-600 w-[100px] h-[100px]";
    case "startNode":
      return "fill-green-50 stroke-green-600 w-[100px] h-[100px]";
    default:
      return "fill-neutral-500";
  }
};

const WorkflowStudioComponent = ({
  nodes,
  edges,
  warnings,
  setEdges,
  setNodes,
  autoArrange,
}: WorkflowStudioProps) => {
  const [showMiniMap, setShowMiniMap] = useState(false);
  const {
    saveMeta,
    updateWorkflow,
    isWorkflowEditable,
    showDeleteConfirmation,
    setShowDeleteConfirmation,
    newNode,
    isFetchingWf,
    workflow,
    setNewNode,
    accessError,
  } = useWorkflowContext();
  const { project, deleteElements, getNodes, screenToFlowPosition } =
    useReactFlow();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeModal, setActiveModal] = useState<
    "workflow" | "changelog" | null
  >(null);

  const setIsWorkflowInfo = (isOpen: boolean) => {
    setActiveModal(isOpen ? "workflow" : null);
  };

  const setIsChangeLog = (isOpen: boolean) => {
    setActiveModal(isOpen ? "changelog" : null);
  };
  const queryClient = useQueryClient();
  const { onAddNode } = useAddNode();
  const newStartNodeRef = useRef<OnConnectStartParams | undefined>();
  const { disposeAllExceptRoot } = useMonacoContext();

  const [isImportNodeFileDragging, setIsImportNodeFileDragging] =
    useState(false);

  const qc = useQueryClient();
  const { processImportedFile } = useImportNodeWithInput();

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (
        e.target instanceof HTMLInputElement ||
        e.target instanceof HTMLTextAreaElement ||
        !isWorkflowEditable
      )
        return;
      if (e.key === "Backspace" || e.key === "Delete") {
        const node = getNodes().find((n) => n.selected);
        if (!node) return;
        setShowDeleteConfirmation({ id: node.id, label: node.data.label });
      }
    };
    document.addEventListener("keyup", listener);
    return () => document.removeEventListener("keyup", listener);
  }, [getNodes, isWorkflowEditable, setShowDeleteConfirmation]);

  const onNodesChange = useCallback(
    (changes: NodeChange[]) => {
      if (!isWorkflowEditable || isFetchingWf) {
        return;
      }

      // No need to update metadata when the only change is a node selection
      if (changes.every((change) => change.type === "select")) return;
      saveMeta();
      setNodes((nds: Node[]) => applyNodeChanges(changes, nds));
    },
    [isWorkflowEditable]
  );

  useEffect(() => {
    if (!newNode) {
      setEdges((eds) => eds.filter((e) => e.id !== "new-node-edge"));
    }
  }, [newNode, setEdges]);

  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) => {
      if (!isWorkflowEditable) {
        return;
      }
      setEdges((eds: Edge[]) => {
        return applyEdgeChanges(changes, eds);
      });
    },
    [isWorkflowEditable]
  );

  const onConnect = useCallback(
    async (params: Edge | Connection) => {
      setNewNode(undefined);
      if (!isWorkflowEditable) {
        return;
      }
      const e = getAddConnection(params);
      if (e) {
        e;
        setEdges((eds) => addEdge(params, eds));
        await updateWorkflow(e).then(() => {
          queryClient.invalidateQueries(getWorkflowKeywordsQuery());
          queryClient.invalidateQueries(getErrors(workflow?.id));
        });
      }
    },
    [isWorkflowEditable]
  );

  const onEdgesDelete = useCallback(
    (edges: Edge[]) => {
      if (!isWorkflowEditable) {
        return;
      }
      const e = getRemoveConnection(edges);
      if (e) {
        updateWorkflow(e).then(() =>
          queryClient.invalidateQueries(getWorkflowKeywordsQuery())
        );
      }
    },
    [isWorkflowEditable]
  );

  const onNodesDelete = useCallback(
    (nodes: Node[]) => {
      if (!isWorkflowEditable) {
        return;
      }
      const e = getRemoveNode(nodes[0]);
      if (e) {
        updateWorkflow(e).then(() => {
          queryClient.invalidateQueries(getWorkflowKeywordsQuery());
          qc.invalidateQueries(getErrors(workflow?.id));
        });
      }
    },
    [isWorkflowEditable]
  );

  const onDragEndAddNode = (nodes: DragEndEvent) => {
    const activeNode = nodes.active;
    const id = activeNode.id;
    if (!id || !activeNode.data.current) return;

    if (nodes.delta.x < 50 && nodes.delta.y < 50) return;

    const position = project({
      x: activeNode.data.current.initialPosition.x + nodes.delta.x,
      y: activeNode.data.current.initialPosition.y + nodes.delta.y,
    });
    analyticsInstance.triggerAnalytics(PolicyStudioActions.SIDEBAR_ADD_BLOCK, {
      bucket_name: workflow?.policyName ?? "",
      version: workflow?.policyVersion ?? "",
      type: id?.toString().split(":")?.[1] ?? "",
    });
    onAddNode(id.toString(), position);
  };

  const onConnectEnd: OnConnectEnd = (e) => {
    if (!newStartNodeRef.current?.nodeId) {
      return;
    }
    const startNodeId = newStartNodeRef.current?.nodeId;
    const startNodeHandle = newStartNodeRef.current?.handleId;
    const targetIsPane = (e?.target as HTMLElement)?.classList?.contains(
      "react-flow__pane"
    );
    if (
      targetIsPane ||
      (e?.target as HTMLElement).dataset?.nodeid ===
        newStartNodeRef.current.nodeId
    ) {
      setNewNode({
        id: "new-node",
        position: screenToFlowPosition({
          x: (e as MouseEvent).clientX,
          y: (e as MouseEvent).clientY - 200,
        }),
        data: "",
        type: "newNode",
        draggable: false,
      });
      setEdges((eds) =>
        eds.concat({
          id: "new-node-edge",
          source: startNodeId,
          sourceHandle: startNodeHandle,
          target: "new-node",
        })
      );
    }
    setTimeout(() => {
      newStartNodeRef.current = undefined;
    }, 100);
  };

  const onNodeClick: NodeMouseHandler = (e, n) => {
    if (n.id !== "new-node" && n.id !== newStartNodeRef.current?.nodeId) {
      setNewNode(undefined);
    }
  };

  const onPaneClick = () => {
    disposeAllExceptRoot();
    if (!newStartNodeRef.current?.nodeId) {
      setNewNode(undefined);
    }
  };

  if (newNode) {
    nodes = [...nodes, newNode];
  }

  const maxZoom = Number(
    localStorage.getItem(LOCALSTORAGE_KEYS.STUDIO_MAX_ZOOM) ?? "1"
  );

  const dropHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsImportNodeFileDragging(false);
    let files: (File | null)[] = [];
    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      Array.from(e.dataTransfer.items).forEach((item) => {
        if (item.kind === "file") {
          const file = item.getAsFile();
          if (!file) return;
          files.push(file);
        }
      });
    } else {
      // Use DataTransfer interface to access the file(s)
      files = Array.from(e.dataTransfer.files);
    }

    files.forEach((file) => {
      if (!file)
        return notify({ title: "Invalid File", text: "File is empty" });
      if (!file.name.endsWith(".json"))
        return notify({ title: "Invalid File", text: "File is not json" });
      processImportedFile(file, { globalImport: true });
    });
  };

  useEffect(() => {
    const overListener = (e: DragEvent) => {
      e.preventDefault();
      setIsImportNodeFileDragging(!isSidebarOpen && true);
    };
    const leaveListener = (e: DragEvent) => {
      e.preventDefault();
      setIsImportNodeFileDragging(false);
    };
    document.addEventListener("dragover", overListener);
    document.addEventListener("dragleave", leaveListener);
    return () => {
      document.removeEventListener("dragover", overListener);
      document.removeEventListener("dragleave", leaveListener);
    };
  }, [isSidebarOpen]);

  if (accessError && accessError?.message === AccessDeniedErrorMessage)
    return (
      <div className="h-[100vh]">
        <AccessDenied />
      </div>
    );
  const handleWorkflowInfoClose = () => {
    setIsWorkflowInfo(false);
  };
  const handleChangeLogClose = () => {
    setIsChangeLog(false);
  };
  return (
    <>
      <div style={{ width: "100vw", height: "100vh" }}>
        <WorkflowStudioTopbar
          setIsChangeLog={setIsChangeLog}
          setIsWorkflowInfo={setIsWorkflowInfo}
        />
        <DndContext onDragEnd={onDragEndAddNode}>
          <div className="flex h-[calc(100vh-2.5rem)] w-screen">
            <Sidebar
              warnings={warnings}
              isSidebarOpen={isSidebarOpen}
              setIsSidebarOpen={setIsSidebarOpen}
            />
            <div className="flex-1">
              <ReactFlow
                nodes={nodes}
                edges={edges}
                nodeTypes={nodeTypes}
                onInit={(reactFlowInstance) =>
                  reactFlowInstance.zoomTo(100, { duration: 10 })
                }
                edgeTypes={edgeTypes}
                selectNodesOnDrag={false}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onEdgesDelete={onEdgesDelete}
                onNodesDelete={onNodesDelete}
                isValidConnection={isValidConnection}
                connectionRadius={30}
                maxZoom={maxZoom}
                minZoom={0.6}
                panOnScroll
                zoomOnPinch
                fitView
                snapToGrid
                snapGrid={[16, 16]}
                deleteKeyCode={[]}
                onConnectStart={(e, n) => {
                  newStartNodeRef.current = n;
                }}
                onConnectEnd={onConnectEnd}
                onNodeClick={onNodeClick}
                onPaneClick={onPaneClick}
                proOptions={{
                  hideAttribution: true,
                }}
              >
                <Background
                  gap={16}
                  style={{ background: "#f6f6f6" }}
                  color="#DFDFDF"
                  size={2}
                />
                <CustomControls
                  setShowMiniMap={setShowMiniMap}
                  showMiniMap={showMiniMap}
                  autoArrange={autoArrange}
                  className="mb-2 ml-2.5"
                />
                {showMiniMap && (
                  <MiniMap
                    nodeStrokeWidth={4}
                    nodeBorderRadius={20}
                    nodeClassName={nodeClassName}
                    position="bottom-left"
                    zoomable
                    pannable
                    className="mb-2 border-[10px] ring-1 ring-neutral-100 border-white rounded-md -translate-y-1/3 translate-x-1/4"
                  />
                )}
              </ReactFlow>
            </div>
            {workflow && activeModal === "workflow" && (
              <WorkflowInfo setIsWorkflowInfo={handleWorkflowInfoClose} />
            )}
            {workflow && activeModal === "changelog" && (
              <WfChangeLog
                setIsChangeLog={handleChangeLogClose}
                bucketId={workflow.policyBucketId}
                toWfId={workflow.id}
                toWfName={workflow.name}
              />
            )}
          </div>
        </DndContext>
      </div>
      {!isSidebarOpen && (
        <div
          className={clsx(
            "opacity-80 flex bg-white fixed inset-0 font-h1 justify-center items-center border-4 border-dashed border-neutral-300",
            !isImportNodeFileDragging && "hidden"
          )}
          onDrop={dropHandler}
        >
          Drop an exported node file to import it.
        </div>
      )}

      {showDeleteConfirmation && (
        <ConfirmationModal
          isOpen={!!showDeleteConfirmation}
          onClose={() => setShowDeleteConfirmation(false)}
          title="Confirm deletion"
          action={() => {
            deleteElements({ nodes: [{ id: showDeleteConfirmation.id }] });
          }}
          destructive
          actionText="Delete"
        >
          <div className="font-normal break-words">
            Are you sure you want to delete {showDeleteConfirmation.label}?
          </div>
        </ConfirmationModal>
      )}
      <Outlet />
    </>
  );
};

const WorkflowStudio = (props: WorkflowStudioProps) => (
  <MonacoProvider>
    <WorkflowStudioComponent {...props} />
  </MonacoProvider>
);

export default React.memo(withSWtoReactFlow(WorkflowStudio));
