import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Badge from "src/components/Badge";
import Button from "src/components/Button";
import Modal from "src/components/Dialogue";
import Dropdown from "src/components/DropDown";
import Loader from "src/components/Loader";
import { CHANGE_LOG_NO_VERSION_LIST } from "src/constants/imageConstants";
import { getChangeLogWfVersionList } from "src/screens/workflow/queries";
import { classNames } from "src/utils/utils";
import { useQuery } from "@tanstack/react-query";

export const WfChangeLogModal = ({
  setIsChangeLog,
  bucketId,
  toWfId,
  toWfName,
  setFromVersion,
}: {
  setIsChangeLog: React.Dispatch<React.SetStateAction<boolean>>;
  bucketId: string;
  toWfId: string;
  toWfName: string;
  setFromVersion: React.Dispatch<React.SetStateAction<string | null>>;
}) => {
  const navigate = useNavigate();
  const {
    data: versionList,
    isLoading,
    isSuccess,
    isError,
  } = useQuery(getChangeLogWfVersionList(bucketId, toWfId));
  const [selectedVersion, setSelectedVersion] = useState<string>();
  return (
    <Modal
      onClose={() => {
        setIsChangeLog(false);
        setFromVersion(null);
      }}
      open={true}
    >
      <Modal.Panel size="sm">
        <Modal.Header>Version Change Logs</Modal.Header>
        <Modal.Body>
          <>
            {versionList && versionList?.length > 0 ? (
              <div>
                <p className="font-b2 text-neutral-500 flex flex-wrap">
                  Pick a version to compare with the
                  <span className="text-neutral-black font-b2-medium mx-1">
                    {toWfName}
                  </span>
                  and view the changes.
                </p>
                <div className="mt-5">
                  <label className="text-neutral-black !font-b2">
                    Select a Version for Comparison
                  </label>
                  <Dropdown
                    value={selectedVersion}
                    onChange={(e: string) => {
                      setSelectedVersion(e);
                    }}
                  >
                    <Dropdown.Button
                      className={classNames(
                        "bg-neutral-0 w-full mt-1 !focus:ring-0",
                        !!selectedVersion && "font-b2-medium text-neutral-black"
                      )}
                    >
                      <span className="max-w-[300px] truncate">
                        {selectedVersion ?? "Choose a version"}
                      </span>
                    </Dropdown.Button>
                    <Dropdown.Options className="ml-10 h-auto max-h-40 !w-[440px] ">
                      {isLoading && <Loader size="xs" />}
                      {isSuccess && versionList?.length > 0 ? (
                        versionList?.map((r) => (
                          <Dropdown.Option key={r.name} value={r.name}>
                            <div className="flex justify-between items-center w-full">
                              <span>{r.name}</span>
                              {r.parent && (
                                <Badge variant="primary">source</Badge>
                              )}
                            </div>
                          </Dropdown.Option>
                        ))
                      ) : (
                        <Dropdown.Option key="empty" disabled value="empty">
                          No options
                        </Dropdown.Option>
                      )}
                      {isError && (
                        <Dropdown.Option key="error" disabled value="error">
                          An error occured
                        </Dropdown.Option>
                      )}
                    </Dropdown.Options>
                  </Dropdown>
                </div>
              </div>
            ) : (
              <NoVersionListState />
            )}
          </>
        </Modal.Body>
        <Modal.Footer>
          {versionList && versionList?.length > 0 ? (
            <Button
              variant="primary"
              disabled={
                !selectedVersion || !versionList || versionList?.length === 0
              }
              onClick={() => {
                if (versionList && versionList?.length > 0) {
                  setFromVersion(
                    versionList?.find((obj) => obj.name === selectedVersion)!.id
                  );
                }
              }}
            >
              View Changelogs
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={() => {
                navigate(`/details/${bucketId}`);
              }}
            >
              Add version
            </Button>
          )}
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  );
};
const NoVersionListState = () => {
  return (
    <div className="m-auto flex flex-col items-center gap-3 justify-center text-center">
      <img
        className="w-[124px] h-[80px] mt-2"
        src={CHANGE_LOG_NO_VERSION_LIST}
        alt=""
      />
      <span className="font-b1-medium text-neutral-black">
        No Versions Available for Comparison
      </span>
      <span className="font-b2-medium text-neutral-500">
        There are no versions at the moment. New versions will appear here when
        available.
      </span>
    </div>
  );
};
