import chevronDown from "@assets/icons/chevron-down.svg";
import { FloatProvider, useFloat } from "@context/FloatContext";
import { FloatingPortal } from "@floating-ui/react";
import { Combobox } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { classNames, debounce, highlightAfterLastSlash } from "@utils/utils";
import { ReactNode } from "react";

type Props<T extends Record<string, string>> =
  | {
      onChange: (e: T) => void;
      selected: T;
      className?: string;
      options: T[];
      displayKey: keyof T;
      onSearchChange: (e: string) => void;
      name?: string;
      disableShadow?: boolean;
      panelMaxWidth?: boolean;
      panelClassNames?: string;
      disabled?: boolean;
      hightlightPath?: boolean;
      placeholder?: string;
    }
  | {
      onChange: (e: T) => void;
      selected: T;
      className?: string;
      children: ReactNode;
      displayKey: keyof T;
      onSearchChange: (e: string) => void;
      name?: string;
      disableShadow?: boolean;
      panelMaxWidth?: boolean;
      panelClassNames?: string;
      disabled?: boolean;
      hightlightPath?: boolean;
      placeholder?: string;
    };

export default function DropDownRoot<T extends Record<string, string>>({
  ...props
}: Props<T>) {
  return (
    <FloatProvider placement="bottom">
      <Autocomplete {...props} />
    </FloatProvider>
  );
}

function Autocomplete<T extends Record<string, string>>({
  onChange,
  selected,
  className = "w-full",
  displayKey,
  onSearchChange,
  disabled,
  panelClassNames,
  name,
  placeholder,
  disableShadow = false,
  hightlightPath = false,
  panelMaxWidth = false,
  ...props
}: Props<T>) {
  const { x, y, strategy, refs } = useFloat();
  return (
    <Combobox
      as="div"
      className={classNames("relative h-min", className)}
      value={selected}
      onChange={(e: T) => {
        onChange(e);
      }}
      name={name}
      disabled={disabled}
    >
      <div className="relative mt-1">
        <Combobox.Button
          ref={refs.setReference}
          className="flex items-center rounded-r-md focus:outline-none w-full"
        >
          {({ open }) => {
            return (
              <>
                <Combobox.Input
                  autoComplete="off"
                  placeholder={placeholder ?? "Search"}
                  className={classNames(
                    "w-full rounded-md border border-neutral-100 disabled:cursor-not-allowed bg-white flex items-center h-7 pl-3 pr-8 focus:border-primary-400 focus:ring-0 focus:outline-none font-b2 placeholder:text-neutral-500",
                    {
                      "shadow-sm": !disableShadow,
                    }
                  )}
                  onChange={debounce((e) => onSearchChange(e.target.value))}
                  displayValue={(op: T) => op[displayKey]}
                />
                <img
                  src={chevronDown}
                  className={classNames(
                    "absolute right-3 duration-100",
                    open && "rotate-180"
                  )}
                  aria-hidden="true"
                  alt=""
                />
              </>
            );
          }}
        </Combobox.Button>
        <FloatingPortal>
          <Combobox.Options
            style={{
              minWidth: "350px",
              width: panelMaxWidth
                ? "fit-content"
                : refs.reference.current?.getBoundingClientRect().width,
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              zIndex: 9999,
            }}
            ref={refs.setFloating}
            className={classNames(
              "absolute z-[201] -mt-1 max-h-60 w-full overflow-auto rounded-md bg-white shadow-sm focus:outline-none font-b2 border border-neutral-100",
              panelClassNames
            )}
          >
            {"children" in props
              ? props.children
              : (props.options || []).map((op) => (
                  <Combobox.Option
                    key={op[displayKey]}
                    value={op}
                    className={({ active }) =>
                      classNames(
                        "relative cursor-pointer select-none py-2 px-3 text-neutral-black",
                        active ? "bg-neutral-50" : ""
                      )
                    }
                  >
                    {({ active, selected }) => (
                      <>
                        <span
                          className={classNames(
                            "block truncate",
                            selected && "font-semibold"
                          )}
                        >
                          {hightlightPath
                            ? highlightAfterLastSlash(op[displayKey])
                            : op[displayKey]}
                        </span>
                        {selected && (
                          <span
                            className={classNames(
                              "absolute inset-y-0 left-0 flex items-center pl-1.5",
                              active ? "text-white" : "text-primary-700"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Combobox.Option>
                ))}
          </Combobox.Options>
        </FloatingPortal>
      </div>
    </Combobox>
  );
}
