import plusIcon from "@assets/icons/workflow/plus-add-condition.svg";
import { ReactComponent as TrashIcon } from "@assets/icons/workflow/trash-02.svg";
import closeIcon from "@assets/icons/x-close.svg";
import Button from "@components/Button";
import CustomEditor from "@components/Editor/CustomEditor";
import Label from "@components/Label";
import useOnClickOutside from "@hooks/useOnClickOutside";
import { useWorkflowContext } from "@screens/workflow/WorkflowContext";
import { getErrors, getWorkflowKeywordsQuery } from "@screens/workflow/queries";
import { MatrixItem } from "@screens/workflow/studio/components/DecisionTable/TableCell";
import MatrixHeader from "@screens/workflow/studio/components/DecisionTable/TableHeader";
import { FormType } from "@screens/workflow/studio/components/DecisionTable/types";
import {
  transformColumnArrayToRequest,
  transformResponseToColumnArray,
} from "@screens/workflow/studio/components/DecisionTable/utils";
import ModelSetTableTest from "@screens/workflow/studio/components/ModelSet/ModelSetTableTest";
import {
  getModelSetItemsQuery,
  useUpdateModelSetItem,
} from "@screens/workflow/studio/components/ModelSet/queries";
import { DecisionTableRules } from "@screens/workflow/studio/components/ModelSet/types";
import { NodeName } from "@screens/workflow/studio/components/NodeName";
import useKeywordsFromWorkflowKeywords from "@screens/workflow/studio/hooks/useKeywordsFromWorkflowKeywords";
import { ModelSetId } from "@screens/workflow/types";
import { useQueryClient } from "@tanstack/react-query";
import logger from "@utils/Logger";
import {
  generateAndDownloadFile,
  getNetworkErrorText,
  notify,
} from "@utils/utils";
import clsx from "clsx";
import { parse } from "csv-parse/browser/esm/sync";
import { stringify as csvStringify } from "csv-stringify/browser/esm/sync";
import {
  ChangeEvent,
  Fragment,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

export default function ModelSetTableEditor() {
  const { workflowId, nodeId, itemId } = useParams<{
    workflowId: string;
    nodeId: string;
    itemId: string;
  }>();

  const [currentlyEditing, _setCurrentlyEditing] = useState<
    [number, number] | null
  >(null);

  const [showTest, setShowTest] = useState(false);

  const setCurrentlyEditing = (value: typeof currentlyEditing) => {
    if (isWorkflowEditable) _setCurrentlyEditing(value);
  };

  const ref = useRef<HTMLDivElement>(null);
  const lastColumnRef = useRef<HTMLDivElement>(null);

  const { isWorkflowEditable } = useWorkflowContext();

  const keywordsQuery = useKeywordsFromWorkflowKeywords(workflowId, nodeId);

  const queryClient = useQueryClient();

  useEffect(() => {
    document.body.classList.add("overflow-hidden");
    return () => document.body.classList.remove("overflow-hidden");
  }, []);

  useOnClickOutside(ref, () => setCurrentlyEditing(null));

  const nodeData =
    queryClient.getQueryData(getModelSetItemsQuery(workflowId, nodeId).queryKey)
      ?.data.data ?? [];

  const matrixItemData = nodeData.find((i) => i.id === itemId)!;

  const availableModelNames = nodeData
    .filter((item) => item.seqNo < matrixItemData.seqNo)
    .map((item) => item.name);

  const matrixName = matrixItemData?.name ?? "";

  const matrixData =
    matrixItemData?.decisionTableRules ??
    ({
      default: "",
      headers: [],
      rows: [],
    } as DecisionTableRules);

  const { control, formState, setValue, handleSubmit, getValues } =
    useForm<FormType>({
      defaultValues: matrixData
        ? transformResponseToColumnArray(matrixData)
        : undefined,
      shouldUnregister: true,
    });

  const rowsFieldArray = useFieldArray({
    control: control,
    name: "rows",
  });

  const colsFieldArray = useFieldArray({
    control: control,
    name: "headers",
  });

  function checkIsEditing(row: number, col: number) {
    if (!currentlyEditing) return false;
    return row === currentlyEditing[0] && col === currentlyEditing[1];
  }

  const navigate = useNavigate();
  const updateMutation = useUpdateModelSetItem(workflowId!, nodeId!, itemId!);

  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const listener = (e: globalThis.KeyboardEvent) => {
      if (e.key !== "Tab") return;
      if (!currentlyEditing) return setCurrentlyEditing([0, 0]);

      const [row, col] = currentlyEditing;
      const headerCount = matrixData.headers.length ?? 0;

      if (row === -1) {
        if (col === headerCount - 1) return setCurrentlyEditing([0, 0]);
      }

      if (col === headerCount) {
        return setCurrentlyEditing([row + 1, 0]);
      }

      setCurrentlyEditing([row, col + 1]);
    };
    document.addEventListener("keypress", listener);
    return () => document.removeEventListener("keypress", listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentlyEditing, matrixData.headers.length]);

  if (!matrixItemData) navigate(`/workflow/${workflowId}`);

  const importTable = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        const result = reader.result as string;

        let rows: string[][] = [];
        try {
          rows = parse(result);
        } catch (err) {
          notify({ title: "Failed to import", text: "Invalid CSV file" });
          logger.error(err);
          return;
        }

        if (rows.length === 0) return;

        const headers = rows.shift()!;

        if (headers.pop()!.toLowerCase() !== "output") {
          notify({ title: "Invalid file", text: "output header is missing" });
          return;
        }

        const body: Array<{
          columns: { name: string; value: string }[];
          output: string;
        }> = [];
        for (let row of rows) {
          const items = row;
          const output = items.pop()!;

          const item = {
            columns: headers.map((header, index) => ({
              name: header,
              value:
                items[index].toUpperCase() === "TRUE"
                  ? "true"
                  : items[index].toUpperCase() === "FALSE"
                  ? "false"
                  : items[index],
            })),
            output,
          };
          body.push(item);
        }

        setValue(
          "headers",
          headers.map((name) => ({ name }))
        );
        setValue(
          "rows",
          transformResponseToColumnArray({ default: "", headers, rows: body })
            .rows
        );

        if (fileInputRef.current) fileInputRef.current.value = "";
      },
      false
    );
    reader.readAsText(file);
  };

  const exportTable = () => {
    if (!matrixData) return;
    const data = csvStringify([
      [...matrixData.headers, "output"],
      ...matrixData.rows.map((row) => [
        ...matrixData.headers.map((_, i) => row?.columns?.[i]?.value),
        row.output,
      ]),
    ]);

    generateAndDownloadFile(data, `${matrixName}.csv`);
  };

  const keyPressHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    if (!currentlyEditing || !matrixData) return;
    const [row, col] = currentlyEditing;
    const key = e.key;
    const headerCount = matrixData.headers.length;

    switch (key) {
      case "ArrowUp":
        // Output header is not editable. Handle that special case
        if (row === 0 && col === headerCount) break;

        setCurrentlyEditing([Math.max(-1, row - 1), col]);
        break;
      case "ArrowDown":
        setCurrentlyEditing([
          Math.min(matrixData.rows.length - 1, row + 1),
          col,
        ]);
        break;
    }
  };

  const updateMatrix = handleSubmit((data) => {
    updateMutation.mutate(
      {
        expression: {
          decisionTableRules: transformColumnArrayToRequest(data),
          name: matrixName,
          seqNo: matrixItemData!.seqNo,
          body: "",
        },
      },
      {
        onSuccess: () =>
          notify({ title: "Saved", text: "Updated table", type: "success" }),
        onError: (err) =>
          notify({ title: "Failed", text: getNetworkErrorText(err) }),
      }
    );
  });

  const addRow = () => {
    rowsFieldArray.append({
      column: new Array(colsFieldArray.fields.length).fill(""),
      output: "",
    });
  };

  const addColumn = () => {
    colsFieldArray.append({ name: "" });
    getValues("rows").forEach((row, rowIndex) => {
      const updatedColumns = [...(row?.column ?? []), ""];
      setValue(`rows.${rowIndex}.column`, updatedColumns);
    });
    setCurrentlyEditing([-1, colsFieldArray.fields.length]);
    setTimeout(() => {
      if (lastColumnRef.current && ref.current) {
        const container = ref.current;
        const lastColumn = lastColumnRef.current;

        const offset = lastColumn.offsetLeft + lastColumn.offsetWidth;
        const maxScrollLeft = container.scrollWidth - container.clientWidth;

        container.scrollLeft = Math.min(offset - 20, maxScrollLeft);
      }
    }, 0);
  };

  const getDeleteColumn = (index: number) => {
    const updatedRows = getValues("rows").map((row) => {
      const updatedColumns = row.column.filter(
        (_: any, colIndex: number) => colIndex !== index
      );
      return { output: row.output, column: updatedColumns };
    });
    setValue("rows", updatedRows);
    colsFieldArray.remove(index);
  };

  const getDeleteRow = (index: number) => {
    return () => {
      rowsFieldArray.remove(index);
    };
  };

  const updateNodeName = async (name: string) => {
    await updateMutation.mutateAsync({
      expression: {
        name,
        decisionTableRules: matrixData,
        seqNo: matrixItemData?.seqNo,
        body: "",
      },
    });
    queryClient.invalidateQueries(getWorkflowKeywordsQuery());
  };

  let nodeErrors: string[] = [];
  if (itemId)
    nodeErrors =
      queryClient
        .getQueryData(getErrors(workflowId).queryKey)
        ?.data.data[nodeId as ModelSetId]?.errors[itemId]?.split(",") ?? [];

  return (
    <div className="fixed inset-0 w-screen h-screen bg-white py-3 z-[100] flex">
      {showTest && (
        <div className="basis-1/3 relative pl-4">
          <ModelSetTableTest
            expressions={[matrixData.default, ...matrixData.headers]}
          />
        </div>
      )}
      <div
        className={clsx(
          "pr-4 border-l border-neutral-100 pl-4 overflow-auto",
          showTest ? "basis-2/3" : "basis-full"
        )}
      >
        <div className="font-p3-medium flex justify-between items-center">
          <NodeName
            onChange={(name) => updateNodeName(name)}
            defaultName={matrixName || "Table"}
          />
          <img
            alt="x"
            src={closeIcon}
            onClick={() => navigate(`/workflow/${workflowId}`)}
            className="h-4 w-4 p-0 ml-auto cursor-pointer z-10 text-neutral-500"
          />
        </div>

        <div className="flex flex-col gap-4 mt-3 px-5">
          <div className="flex justify-between">
            <div className="w-[248px]">
              <Label>Default Value</Label>
              <Controller
                control={control}
                name="default"
                defaultValue={matrixData.default}
                rules={{
                  required: {
                    value: true,
                    message: "Default value is required",
                  },
                }}
                render={({ field }) => {
                  let value = field.value;
                  if (field.value.length === 1) {
                    value = `0${field.value}`;
                  }
                  return (
                    <CustomEditor
                      value={value}
                      setValue={field.onChange}
                      monacoOptions={{
                        lineNumbers: "off",
                        glyphMargin: false,
                        fontWeight: "400",
                        folding: false,
                        lineDecorationsWidth: 0,
                        lineNumbersMinChars: 0,
                        showFoldingControls: "never",
                      }}
                    />
                  );
                }}
              />
              <div className="text-error-500 font-b2 mt-1">
                {formState.errors.default?.message}
              </div>
            </div>
            <Button
              onClick={() => setShowTest(!showTest)}
              className="ml-auto mr-2"
              variant="outline"
            >
              Test
            </Button>
            <Button onClick={exportTable} className="mr-2" variant="outline">
              Export
            </Button>
            {isWorkflowEditable && (
              <>
                <input
                  accept=".csv"
                  onChange={importTable}
                  ref={fileInputRef}
                  className="hidden"
                  type="file"
                />
                <Button
                  onClick={() => fileInputRef.current?.click()}
                  className="mr-2"
                  variant="outline"
                >
                  Import
                </Button>
                <Button onClick={addColumn} className="mr-2" variant="outline">
                  <img src={plusIcon} alt="+" />
                  Add Column
                </Button>
                <Button onClick={() => updateMatrix()}>Save</Button>
              </>
            )}
          </div>
          <span className="text-error-500 font-b2">
            {nodeErrors.slice(0, 5).map((i) => {
              return (
                <Fragment key={i[1] + i[0]}>
                  {i}
                  <br />
                </Fragment>
              );
            })}
            {nodeErrors.length > 5 && (
              <Fragment>
                +{nodeErrors.length - 5} more errors
                <br />
              </Fragment>
            )}
          </span>
          <div
            ref={ref}
            className="border-t overflow-auto border-l font-b1 border-neutral-100 rounded-md grid divide-neutral-100 min-h-[1ch] max-h-[calc(100vh-250px)]"
            style={{
              gridTemplateColumns: `75px repeat(${
                colsFieldArray.fields.length + 1
              }, minmax(250px, 1fr))`,
            }}
          >
            <div className="bg-neutral-0 sticky z-50 top-0 left-0 overflow-visible w-full font-medium first:rounded-tl-md py-1 px-4 border-r border-b border-neutral-100 h-10 flex items-center">
              Sl. No.
            </div>
            {colsFieldArray.fields.map(({ name, id }, index) => {
              return (
                <div
                  key={id}
                  ref={
                    index === colsFieldArray.fields.length - 1
                      ? lastColumnRef
                      : null
                  }
                  className="sticky z-10 top-0"
                >
                  {colsFieldArray.fields.length > 1 && isWorkflowEditable && (
                    <TrashIcon
                      onClick={() => getDeleteColumn(index)}
                      className="[&:hover>path]:stroke-error-500 cursor-pointer absolute right-2 top-1/2 -translate-y-1/2"
                    />
                  )}
                  <MatrixHeader
                    modelNames={availableModelNames}
                    path={`headers.${index}.name`}
                    nodeId={nodeId}
                    keywords={keywordsQuery}
                    tableName={matrixName}
                    data={name}
                    control={control}
                    setIsEditing={() => setCurrentlyEditing([-1, index])}
                    isEditing={checkIsEditing(-1, index)}
                    headersList={colsFieldArray.fields}
                  />
                </div>
              );
            })}
            <div className="sticky top-0 right-0 z-50 py-1 pl-4 pr-1 -ml-[1px] border-l border-r border-neutral-100 font-medium border-b bg-neutral-0 rounded-tr-md flex items-center">
              Output
            </div>
            {rowsFieldArray.fields.map(({ column, output, id }, rowIndex) => {
              return (
                <Fragment key={id}>
                  <div
                    tabIndex={0}
                    className="first:rounded-tl-md py-1 pr-1 pl-4 font-normal border-r border-b border-neutral-100 h-10 flex items-center w-full sticky left-0 bg-white"
                  >
                    {rowIndex + 1}
                    <TrashIcon
                      onClick={getDeleteRow(rowIndex)}
                      className="[&:hover>path]:stroke-error-500 cursor-pointer absolute right-2 top-1/2 -translate-y-1/2"
                    />
                  </div>
                  {colsFieldArray.fields.map(({ id }, colIndex) => {
                    return (
                      <MatrixItem
                        key={id}
                        setIsEditing={() =>
                          setCurrentlyEditing([rowIndex, colIndex])
                        }
                        isEditing={checkIsEditing(rowIndex, colIndex)}
                        control={control}
                        data={column[colIndex]}
                        path={`rows.${rowIndex}.column.${colIndex}`}
                        keyPressHandler={keyPressHandler}
                      />
                    );
                  })}
                  <div className="sticky right-0 z-10 bg-white border-l -ml-[1px]">
                    <MatrixItem
                      key="output"
                      control={control}
                      data={output}
                      path={`rows.${rowIndex}.output`}
                      setIsEditing={() =>
                        setCurrentlyEditing([
                          rowIndex,
                          colsFieldArray.fields.length,
                        ])
                      }
                      rules={{
                        required: {
                          value: true,
                          message: "required",
                        },
                      }}
                      isEditing={checkIsEditing(
                        rowIndex,
                        colsFieldArray.fields.length
                      )}
                      keyPressHandler={keyPressHandler}
                    />
                  </div>
                </Fragment>
              );
            })}
          </div>
        </div>
        {isWorkflowEditable && (
          <Button
            onClick={addRow}
            className={clsx(
              "self-start mt-3",
              colsFieldArray.fields.length === 0 && "hidden"
            )}
            variant="outline"
          >
            <img src={plusIcon} alt="+" />
            Add Row
          </Button>
        )}
      </div>
    </div>
  );
}
