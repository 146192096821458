import axios from "@axios";
import { DATE_FORMAT, REPORTS_PAGE_LIMIT } from "@config";
import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { DateRange, FinBoxResponse } from "@types";
import {
  getGQEndTime,
  getGQStartTime,
  getNetworkErrorText,
  notify,
} from "@utils/utils";
import { validateEmail } from "@utils/validate";
import { format, subMonths, subWeeks } from "date-fns";
import { ReportItem } from "./types";

export const reportListQuery = (args?: {
  dateRange: DateRange;
  page: number;
  type?: string;
}) =>
  queryOptions({
    queryKey: ["reportList", args],
    queryFn: async () =>
      axios.get<
        FinBoxResponse<{ list: null | ReportItem[]; totalCount: number }>
      >("report/list", {
        params: {
          startDate: getGQStartTime(
            args?.dateRange.startDate ?? null,
            DATE_FORMAT
          ),
          endDate: getGQEndTime(args?.dateRange.endDate ?? null, DATE_FORMAT),
          page: args?.page ?? 1,
          limit: REPORTS_PAGE_LIMIT,
          type: args?.type,
        },
      }),
    refetchInterval: (query) =>
      query.state.data?.data.data.list?.some(
        (item) => item.status.toLowerCase() === "processing"
      )
        ? 5000
        : false,
  });

export function useReportList(args: {
  dateRange: DateRange;
  page: number;
  type?: string;
}) {
  return useQuery(reportListQuery(args));
}

export function useDownloadReport() {
  return useMutation({
    mutationFn: async (reportID: string) =>
      axios.get<FinBoxResponse<string>>("report/download/" + reportID),
    onError: (err) =>
      notify({ title: "Failed", text: getNetworkErrorText(err) }),
    onSuccess: (data) => window.open(data.data.data, "_blank"),
  });
}

export function useRetryReport() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (reportID: string) =>
      axios.get(`/report/retry/${reportID}`),
    onError: (err) =>
      notify({ title: "Failed", text: getNetworkErrorText(err) }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: reportListQuery().queryKey });
      notify({
        title: "Success",
        text: "Report generation started",
        type: "success",
      });
    },
  });
}

export function useGenerateReport() {
  return useMutation({
    mutationFn: async ({
      email,
      fromDate,
      toDate,
      ...params
    }: {
      fromDate?: string | null;
      toDate?: string | null;
      email?: string;
      user_id?: string | null;
      program?: string[] | null;
      policyVersion?: string[] | null;
      decision?: string[] | null;
    }) => {
      if (!validateEmail(email)) throw new Error("Invalid Email");
      if (!fromDate || !toDate) {
        fromDate = format(subWeeks(new Date(), 1), DATE_FORMAT);
        toDate = format(new Date(), DATE_FORMAT);
      }
      return axios.get(`outcomes/report`, {
        params: {
          email,
          fromDate: getGQStartTime(fromDate, DATE_FORMAT),
          toDate: getGQEndTime(toDate, DATE_FORMAT),
          ...Object.fromEntries(
            (Object.keys(params) as Array<keyof typeof params>)
              .filter((k) => params[k])
              .map((k) => [k, params[k]])
          ),
        },
      });
    },
    onError: (err) =>
      notify({ title: "Failed", text: getNetworkErrorText(err) }),
    onSuccess: () =>
      notify({
        title: "Success",
        text: "Report generation started",
        type: "success",
      }),
  });
}

export function useEndpointGenerateReport() {
  return useMutation({
    mutationFn: async ({
      email,
      fromDate,
      toDate,
      endpointId,
    }: {
      fromDate?: string | null;
      toDate?: string | null;
      email?: string;
      endpointId?: string | null;
    }) => {
      if (!validateEmail(email)) throw new Error("Invalid Email");
      if (!fromDate || !toDate) {
        fromDate = format(subWeeks(new Date(), 1), DATE_FORMAT);
        toDate = format(new Date(), DATE_FORMAT);
      }
      return axios.get(`endpoint/report`, {
        params: {
          email,
          fromDate: getGQStartTime(fromDate, DATE_FORMAT),
          toDate: getGQEndTime(toDate, DATE_FORMAT),
          endpointId: endpointId,
        },
      });
    },
    onError: (err) =>
      notify({ title: "Failed", text: getNetworkErrorText(err) }),
    onSuccess: () =>
      notify({
        title: "Success",
        text: "Report generation started",
        type: "success",
      }),
  });
}

export const policyBucketReportListQuery = () =>
  queryOptions({
    queryKey: ["policyBucketReportListQuery"],
    queryFn: async () =>
      axios.get<
        FinBoxResponse<{
          totalCount: number;
          policyBuckets: Record<string, string>[];
        }>
      >("report/policyBucket/list"),
  });

export function usePolicyBucketReportList() {
  return useQuery(policyBucketReportListQuery());
}

export const policyVersionReportListQuery = ({
  bucketId,
}: {
  bucketId: string;
}) =>
  queryOptions({
    queryKey: ["policyVersionReportListQuery", bucketId],
    queryFn: async () =>
      axios.get<
        FinBoxResponse<{
          totalCount: number;
          policyBucketEntities: Record<string, string>[];
        }>
      >(`report/policyBucket/${bucketId}/entity/list`),
    enabled: !!bucketId,
  });

export function usePolicyVersionReportList({ bucketId }: { bucketId: string }) {
  return useQuery(policyVersionReportListQuery({ bucketId }));
}

export const endpointReportListQuery = () =>
  queryOptions({
    queryKey: ["endpointReportListQuery"],
    queryFn: async () =>
      axios.get<FinBoxResponse<Record<string, string>[]>>(
        "report/endpoint/list"
      ),
  });

export function useEndpointReportList() {
  return useQuery(endpointReportListQuery());
}

export function useGenerateEndpointEvalReport() {
  return useMutation({
    mutationFn: async ({
      email,
      endpointId,
      fromDate,
      toDate,
    }: {
      email: string;
      endpointId: string;
      fromDate?: string | null;
      toDate?: string | null;
    }) => {
      if (!validateEmail(email)) throw new Error("Invalid Email");
      if (!fromDate || !toDate) {
        fromDate = format(subMonths(new Date(), 1), DATE_FORMAT);
        toDate = format(new Date(), DATE_FORMAT);
      }
      return axios.get("report/endpoint/evaluations", {
        params: {
          email,
          endpointId,
          fromDate: getGQStartTime(fromDate, DATE_FORMAT),
          toDate: getGQEndTime(toDate, DATE_FORMAT),
        },
      });
    },
    onError: (err) =>
      notify({ title: "Failed", text: getNetworkErrorText(err) }),
    onSuccess: () =>
      notify({
        title: "Success",
        text: "Endpoint evaluation report generation started",
        type: "success",
      }),
  });
}

export function useGenerateEndpointPredReport() {
  return useMutation({
    mutationFn: async ({
      email,
      endpointId,
      fromDate,
      toDate,
    }: {
      email: string;
      endpointId: string;
      fromDate?: string | null;
      toDate?: string | null;
    }) => {
      if (!validateEmail(email)) throw new Error("Invalid Email");
      if (!fromDate || !toDate) {
        fromDate = format(subMonths(new Date(), 1), DATE_FORMAT);
        toDate = format(new Date(), DATE_FORMAT);
      }
      return axios.get("report/endpoint/predictors", {
        params: {
          email,
          endpointId,
          fromDate: getGQStartTime(fromDate, DATE_FORMAT),
          toDate: getGQEndTime(toDate, DATE_FORMAT),
        },
      });
    },
    onError: (err) =>
      notify({ title: "Failed", text: getNetworkErrorText(err) }),
    onSuccess: () =>
      notify({
        title: "Success",
        text: "Endpoint predictor report generation started",
        type: "success",
      }),
  });
}

export function useGenerateWorkflowPredReport() {
  return useMutation({
    mutationFn: async ({
      email,
      policyId,
      fromDate,
      toDate,
    }: {
      email: string;
      policyId: string;
      fromDate?: string | null;
      toDate?: string | null;
    }) => {
      if (!validateEmail(email)) throw new Error("Invalid Email");
      if (!fromDate || !toDate) {
        fromDate = format(subMonths(new Date(), 1), DATE_FORMAT);
        toDate = format(new Date(), DATE_FORMAT);
      }
      return axios.get("report/workflow/predictors", {
        params: {
          email,
          policyId,
          fromDate: getGQStartTime(fromDate, DATE_FORMAT),
          toDate: getGQEndTime(toDate, DATE_FORMAT),
        },
      });
    },
    onError: (err) =>
      notify({ title: "Failed", text: getNetworkErrorText(err) }),
    onSuccess: () =>
      notify({
        title: "Success",
        text: "Workflow predictor report generation started",
        type: "success",
      }),
  });
}

export function useGenerateWorkflowEvalReport() {
  return useMutation({
    mutationFn: async ({
      email,
      policyId,
      fromDate,
      toDate,
    }: {
      email: string;
      policyId: string;
      fromDate?: string | null;
      toDate?: string | null;
    }) => {
      if (!validateEmail(email)) throw new Error("Invalid Email");
      if (!fromDate || !toDate) {
        fromDate = format(subMonths(new Date(), 1), DATE_FORMAT);
        toDate = format(new Date(), DATE_FORMAT);
      }
      return axios.get("report/workflow/evaluations", {
        params: {
          email,
          policyId,
          fromDate: getGQStartTime(fromDate, DATE_FORMAT),
          toDate: getGQEndTime(toDate, DATE_FORMAT),
        },
      });
    },
    onError: (err) =>
      notify({ title: "Failed", text: getNetworkErrorText(err) }),
    onSuccess: () =>
      notify({
        title: "Success",
        text: "Workflow predictor report generation started",
        type: "success",
      }),
  });
}

export function useGeneratePolicyPredReport() {
  return useMutation({
    mutationFn: async ({
      email,
      policyId,
      fromDate,
      toDate,
    }: {
      email: string;
      policyId: string;
      fromDate?: string | null;
      toDate?: string | null;
    }) => {
      if (!validateEmail(email)) throw new Error("Invalid Email");
      if (!fromDate || !toDate) {
        fromDate = format(subMonths(new Date(), 1), DATE_FORMAT);
        toDate = format(new Date(), DATE_FORMAT);
      }
      return axios.get("report/policy/predictors", {
        params: {
          email,
          policyId,
          fromDate: getGQStartTime(fromDate, DATE_FORMAT),
          toDate: getGQEndTime(toDate, DATE_FORMAT),
        },
      });
    },
    onError: (err) =>
      notify({ title: "Failed", text: getNetworkErrorText(err) }),
    onSuccess: () =>
      notify({
        title: "Success",
        text: "Policy predictor report generation started",
        type: "success",
      }),
  });
}

export function useGeneratePolicyEvalReport() {
  return useMutation({
    mutationFn: async ({
      email,
      policyId,
      fromDate,
      toDate,
    }: {
      email: string;
      policyId: string;
      fromDate?: string | null;
      toDate?: string | null;
    }) => {
      if (!validateEmail(email)) throw new Error("Invalid Email");
      if (!fromDate || !toDate) {
        fromDate = format(subMonths(new Date(), 1), DATE_FORMAT);
        toDate = format(new Date(), DATE_FORMAT);
      }
      return axios.get("report/policy/evaluations", {
        params: {
          email,
          policyId,
          fromDate: getGQStartTime(fromDate, DATE_FORMAT),
          toDate: getGQEndTime(toDate, DATE_FORMAT),
        },
      });
    },
    onError: (err) =>
      notify({ title: "Failed", text: getNetworkErrorText(err) }),
    onSuccess: () =>
      notify({
        title: "Success",
        text: "Policy predictor report generation started",
        type: "success",
      }),
  });
}
