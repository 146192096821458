import classNames from "clsx";
import ProtectedComponent from "src/components/ProtectedComponent";
import Tooltip from "src/components/Tooltip";
import { PERMISSIONS_TYPE } from "src/constants/permissionsConstant";
import { PermissionTypes } from "src/types";
import checkIcon from "@assets/icons/check-blue.svg";
import { ReactComponent as DotsIcon } from "@assets/icons/dots-horizontal.svg";
import failIcon from "@assets/icons/workflow/reject.svg";
import Popover from "@components/Popover";
import { ArrowPathIcon, TrashIcon } from "@heroicons/react/24/outline";
import { LookupData } from "../types";
import { LookupActions } from "src/constants/EventAnalytics";
import { analyticsInstance } from "src/config/event-analytics";

type Props = {
    row: LookupData;
    defaultRowClassNames:string;
    deleteLookupRow: (arg: LookupData) => void;
    updateLookupRow: (arg: LookupData) => void;
};

const LookUpTableRow = ({
    row,
    defaultRowClassNames,
    deleteLookupRow,
    updateLookupRow,
}: Props) => {

    const handleDelete = (e:React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        deleteLookupRow(row);
        analyticsInstance.triggerAnalytics(LookupActions.DELETE,{name:row.name});
    }
    
    const handleUpdate = () => {
        updateLookupRow(row);
    }

    return (
        <tr
            key={row.id}
            className={classNames(
                defaultRowClassNames,
                "!cursor-pointer"
            )}
            onClick={handleUpdate}
        >
            <td
                className=" max-w-[225px] truncate !font-medium pr-5"
                style={{ overflow: "hidden" }}
            >
                {row.name}
            </td>
            <td className="truncate !overflow-hidden max-w-[400px] pr-8">
                {row.alias}
            </td>
            <td className="min-w-[60px]">
                {row.confidential ? (
                    <Tooltip
                        content={
                            <img
                                src={checkIcon}
                                alt="check"
                                className="w-4 h-4"
                            />
                        }
                        tooltipContent="This file is confidential"
                    ></Tooltip>
                ) : (
                    ""
                )}
            </td>
            <td className="min-w-[60px]">
                {row.status === "failed" && (
                    <Tooltip
                        content={
                            <img
                                src={failIcon}
                                alt="failed"
                                className="w-4 h-4"
                            />
                        }
                        tooltipClassName="!translate-y-[8px]"
                        tooltipContent={
                            <p>
                                The last file uploaded could not be processed,{" "}
                                <br /> variables from please correct the file and
                                try again.
                            </p>
                        }
                    ></Tooltip>
                )}
                {row.status === "processing" && (
                    <Tooltip
                        content={
                            <ArrowPathIcon className="w-4 h-4 stroke-neutral-500 animate-spin" />
                        }
                        tooltipContent="Please wait for few minutes while we process this file"
                    ></Tooltip>
                )}
            </td>
            <td>
                <ProtectedComponent
                    type={
                        PERMISSIONS_TYPE.dataSourcesLookup as PermissionTypes
                    }
                    action="delete"
                >
                    <Popover>
                        {({ open }) => (
                            <>
                                <Popover.Button
                                    className={classNames(
                                        "w-7 h-7 duration-150 p-1.5 flex rounded-md hover:bg-neutral-50",
                                        open ? "bg-neutral-50" : ""
                                    )}
                                >
                                    <DotsIcon className="w-4 h-4 stroke-neutral-600 [&>path]:stroke-neutral-600" />
                                </Popover.Button>
                                <Popover.Panel className="text-xs" static>
                                    <div
                                        className="p-2 flex gap-2 cursor-pointer items-center font-b2-medium bg-white  hover:bg-neutral-25 !text-error-600"
                                        onClick={handleDelete}
                                    >
                                        <TrashIcon className="z-10 h-4 w-4 cursor-pointer [&>path]:stroke-error-600" />
                                        Delete
                                    </div>
                                </Popover.Panel>
                            </>
                        )}
                    </Popover>
                </ProtectedComponent>
            </td>
        </tr>
    );
};

export default LookUpTableRow;
