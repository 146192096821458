import { clsx } from "clsx";
import { useState } from "react";
import { Node, useReactFlow } from "reactflow";
import { ReactComponent as GlobeIcon } from "@assets/icons/workflow/globe-01.svg";
import { useWorkflowContext } from "@screens/workflow/WorkflowContext";
// Note: Commenting this for because not using decision node in workflow
// import DecisionTableNode from "@screens/workflow/components/Debugger/subComponents/DecisionTableNode";
import {
  BRANCH_NODE_TYPE,
  DECISION_TABLE_NODE_TYPE,
  MODEL_NODE_TYPE,
  MODEL_SET_NODE_TYPE,
  RULE_GROUP_NODE_TYPE,
  RULE_SET_NODE_TYPE,
} from "@screens/workflow/config";
import Search from "@components/Search";
import { getIcon } from "../../utils";
import BranchNode from "./subComponents/BranchNode";
import GlobalPolicy from "./subComponents/GlobalPolicy";
import ModelNode from "./subComponents/ModelNode";
import ModelsetNode from "./subComponents/ModelsetNode";
import RuleSetNode from "./subComponents/RuleSetNode";
import { analyticsInstance } from "src/config/event-analytics";
import { PolicyStudioActions } from "src/constants/EventAnalytics";

const Debugger = () => {
  const { setCenter, getNodes } = useReactFlow();
  const { setIsShowingTestResult, debuggerNodeId, setDebuggerNodeId, workflow } =
    useWorkflowContext();
  const nodes = getNodes();
  const [searchQuery, setSearchQuery] = useState("");

  const selectedNode = nodes.find((n) => n.id === debuggerNodeId);

  const setSelectedNode = (value?: typeof selectedNode) => {
    if (value) {
      setIsShowingTestResult(false);
      setDebuggerNodeId(value.id);
    } else setDebuggerNodeId(null);
  };

  const handleNodePosition = (node: Node) => {
    setSelectedNode(node);
    setCenter(
      node.position.x + 180,
      node.position.y + 90 + (node.height || 0) / 2,
      {
        duration: 500,
        zoom: 1,
      }
    );

    analyticsInstance.triggerAnalytics(
      PolicyStudioActions.SIDEBAR_TEST_WORKFLOW_BLOCK_SELECT,
      {
        bucket_name: workflow?.policyName ?? '',
        version: workflow?.policyVersion ?? '',
        block_name:node.data.label,
        type:node.type
      }
    );
  };

  const handleGlobalClick = () => {
    setSelectedNode();
  };

  const onSearchChange = (val: string) => {
    setSearchQuery(val);
    setSelectedNode();
  };

  const isGlobal = !selectedNode;

  return (
    <div className="w-full h-full flex">
      <div className="min-w-[200px] overflow-y-auto border-r h-full p-2">
        <Search
          className="w-[168px] mb-3 !mr-0"
          onSearchChange={(e) => onSearchChange(e.target.value)}
        />
        <div
          className={clsx(
            "items-center text-neutral-black flex gap-2 font-b2-medium py-1.5 mb-1.5 cursor-pointer hover:bg-neutral-50 px-2 rounded-md",
            isGlobal ? "bg-neutral-50" : ""
          )}
          onClick={handleGlobalClick}
        >
          <GlobeIcon className="w-4 h-4" />
          <span className="truncate ...">Global Policy</span>
        </div>
        {nodes
          .filter(
            (n) =>
              [
                BRANCH_NODE_TYPE,
                MODEL_NODE_TYPE,
                RULE_GROUP_NODE_TYPE,
                RULE_SET_NODE_TYPE,
                DECISION_TABLE_NODE_TYPE,
                MODEL_SET_NODE_TYPE,
              ].includes(n.type!) &&
              n.data.label.toLowerCase().includes(searchQuery.toLowerCase())
          )
          .map((node) => {
            const isSelected = node.id === debuggerNodeId;
            return (
              <div
                key={node.id}
                onClick={() => handleNodePosition(node)}
                className={clsx(
                  "items-center text-neutral-black flex gap-2 font-b2-medium py-1.5 mb-1.5 cursor-pointer hover:bg-neutral-50 px-2 rounded-md",
                  isSelected ? "bg-neutral-50" : ""
                )}
              >
                <span>{getIcon(node.type, node.data.label)} </span>
                <span className="truncate">{node.data.label}</span>
              </div>
            );
          })}
      </div>
      <div className="w-full p-3 pb-12 relative">
        {isGlobal ? (
          <GlobalPolicy />
        ) : (
          {
            [RULE_GROUP_NODE_TYPE]: <RuleSetNode data={selectedNode} />,
            [RULE_SET_NODE_TYPE]: <RuleSetNode data={selectedNode} />,
            [BRANCH_NODE_TYPE]: <BranchNode data={selectedNode} />,
            [MODEL_NODE_TYPE]: <ModelNode data={selectedNode} />,
            [MODEL_SET_NODE_TYPE]: <ModelsetNode data={selectedNode} />,
            // [DECISION_TABLE_NODE_TYPE]: (
            //   <DecisionTableNode data={selectedNode} />
            // ),
          }[selectedNode.type!]
        )}
      </div>
    </div>
  );
};

export default Debugger;
