import { DASHBOARD_USERS_LIMIT, DATE_FORMAT } from "src/config";
import axios from "@axios";
import {
  keepPreviousData,
  queryOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { FinBoxResponse } from "@types";
import {
  getGQEndTime,
  getGQStartTime,
  getNetworkErrorText,
  normalizeQueryKey,
  notify,
} from "@utils/utils";
import { DashboardUsers } from "../settings/pages/SettingsAdmin/types";
import {
  ActivityLogList,
  PolicyDetailType,
  PolicyDetailsList,
  PolicyVersionList,
} from "./types";

const staleTime = 5 * 60 * 1000;

type EvaluationMetricsArgs = {
  startDate: string;
  endDate: string;
  entityID: string;
  entityType: "policy";
  level: "user" | "reference";
};

export function useEvaluationMetrics({
  startDate,
  endDate,
  entityID,
  entityType,
  level,
}: EvaluationMetricsArgs) {
  return useQuery({
    staleTime,
    queryKey: [
      "evaluationMetrics",
      entityID,
      entityType,
      level,
      startDate,
      endDate,
    ],
    queryFn: async () =>
      axios.get<
        FinBoxResponse<{
          totalCount: number;
          passCount: number;
          rejectCount: number;
          cantDecideCount: number;
        }>
      >("policybucket/entityEvaluationMetrics", {
        params: {
          startDate: getGQStartTime(startDate, DATE_FORMAT),
          endDate: getGQEndTime(endDate, DATE_FORMAT),
          entityId: entityID,
          entityType,
          level,
        },
      }),
  });
}

export function useRuleEvaluationMetrics({
  startDate,
  endDate,
  policyID,
}: {
  startDate: string | null;
  endDate: string | null;
  policyID: string;
}) {
  return useQuery({
    staleTime,
    queryKey: ["ruleEvaluationMetrics", startDate, endDate, policyID],
    queryFn: async () =>
      axios.get<
        FinBoxResponse<null | Array<{
          ruleId: string;
          description: string;
          evaluationMetrics: {
            totalCount: number;
            passCount: number;
            rejectCount: number;
            cantDecideCount: number;
          };
        }>>
      >("policybucket/ruleEvaluationMetrics", {
        params: {
          startDate: getGQStartTime(startDate, DATE_FORMAT),
          endDate: getGQEndTime(endDate, DATE_FORMAT),
          policyId: policyID,
        },
      }),
  });
}

type EntityEvaluationStatsArgs = {
  startDate: string | null;
  endDate: string | null;
  entityID: string;
  entityType: "policy";
  level: "user" | "reference";
};

export function useEntityEvaluationStatistics({
  startDate,
  endDate,
  entityID,
  entityType,
  level,
}: EntityEvaluationStatsArgs) {
  return useQuery({
    staleTime,
    queryKey: [
      "entityEvaluationStatistics",
      startDate,
      endDate,
      entityID,
      entityType,
      level,
    ],
    queryFn: async () =>
      axios.get<
        FinBoxResponse<null | Array<{
          timestamp: string;
          metrics: {
            pass: number;
            reject: number;
          };
          totalCount: number;
        }>>
      >("policybucket/entityEvaluationStatistics", {
        params: {
          startDate: getGQStartTime(startDate, DATE_FORMAT),
          endDate: getGQEndTime(endDate, DATE_FORMAT),
          entityId: entityID,
          entityType,
          level,
        },
      }),
  });
}

export function usePolicyDetails({ policyID }: { policyID?: string }) {
  return useQuery({
    queryKey: ["policyDetails", policyID],
    staleTime: 5 * 60 * 1000,
    queryFn: async () =>
      axios.get<FinBoxResponse<PolicyDetailType>>("policyDetails", {
        params: {
          policyId: policyID,
        },
      }),
    select: (data) => data.data?.data,
  });
}

export function useDownloadPolicyReport() {
  return useMutation({
    mutationFn: async ({
      fromDate,
      toDate,
      policyId,
      email,
    }: {
      fromDate?: string;
      toDate?: string;
      policyId?: string;
      email?: string;
    }) => {
      return axios.get("report", {
        params: {
          policyId,
          email,
          fromDate: getGQStartTime(fromDate || "", "dd MMM, yyyy"),
          toDate: getGQEndTime(toDate || "", "dd MMM, yyyy"),
        },
      });
    },
    onSuccess: (_, variables) =>
      notify({
        title: "Success",
        text: "The report will be mailed to " + variables.email,
        type: "success",
      }),
    onError: (err) =>
      notify({
        title: "Failed",
        text: getNetworkErrorText(err),
      }),
  });
}

export function usePredictorReport() {
  return useMutation({
    mutationFn: async ({
      fromDate,
      toDate,
      policyId,
      email,
    }: {
      fromDate: string;
      toDate: string;
      policyId: string;
      email: string;
    }) =>
      axios.get("predictors", {
        params: {
          policyId,
          email,
          fromDate: getGQStartTime(fromDate || "", "dd MMM, yyyy"),
          toDate: getGQEndTime(toDate || "", "dd MMM, yyyy"),
        },
      }),
    onSuccess: (_, variables) =>
      notify({
        title: "Success",
        text: "The report will be mailed to " + variables.email,
        type: "success",
      }),
    onError: (err) =>
      notify({
        title: "Failed",
        text: getNetworkErrorText(err),
      }),
  });
}

export const policyVersionListQuery = (
  entityId: string,
  args?: {
    status?: string[];
    version?: string;
    pageNo?: number;
    pageSize?: number;
  }
) =>
  queryOptions({
    queryKey: normalizeQueryKey(["policyVersionListQuery", entityId, args]),
    queryFn: async () => {
      return axios.get<
        FinBoxResponse<{
          totalCount: number;
          policyBucketEntities: PolicyVersionList[];
          entityType: number;
        }>
      >(`policybucket/${entityId}/entity/list`, {
        params: {
          pageNo: args?.pageNo,
          pageSize: args?.pageSize,
          status: args?.status,
          version: args?.version,
        },
      });
    },
    enabled: !!entityId,
    select: (data) => data.data.data,
    meta: {
      errorMessage: "Could not get policy version list",
    },
  });

export const policyDetailsQuery = (args?: { policyId?: string }) =>
  queryOptions({
    queryKey: normalizeQueryKey(["policyDetailsQuery", args]),
    queryFn: async () => {
      return axios.get<FinBoxResponse<PolicyDetailsList>>(
        `policybucket/${args?.policyId}/details`
      );
    },
    select: (data) => data.data.data,
    meta: {
      errorMessage: "Could not get policy details",
    },
  });

export const activityLogQuery = (args?: { policyId?: string }) =>
  queryOptions({
    queryKey: normalizeQueryKey(["activityLogQuery", args?.policyId]),
    queryFn: async () => {
      return axios.get<FinBoxResponse<ActivityLogList>>(
        `policybucket/${args?.policyId}/activitylog`
      );
    },
    enabled: !!args?.policyId,
    select: (data) => data.data.data,
    meta: {
      errorMessage: "Could not get activity log",
    },
  });

export const addPolicyVersionListQuery = (entityId?: string) =>
  queryOptions({
    queryKey: normalizeQueryKey(["addPolicyVersionListQuery", entityId]),
    queryFn: async () => {
      return axios.get<FinBoxResponse<Record<string, string>>>(
        `policybucket/${entityId}/nextVersions`
      );
    },
    select: (data) => data.data.data,
    meta: {
      errorMessage: "Could not get policy version list",
    },
  });

export function useAddPolicyVersion() {
  return useMutation({
    mutationFn: async ({ formData }: { formData: FormData }) =>
      axios.post("policybucket/policy/create", formData),
  });
}

export function useAddPolicyImportVersion() {
  return useMutation({
    mutationFn: async ({ formData }: { formData: FormData }) =>
      axios.post<
        FinBoxResponse<{
          entityId: string;
          importErrors: string[];
        }>
      >("policybucket/policy/import", formData),
  });
}

export function useAddDuplicatePolicyVersion() {
  return useMutation({
    mutationFn: async ({
      policyBucketId,
      changeType,
      description,
      fromEntityId,
    }: {
      policyBucketId: string;
      changeType: string;
      description: string;
      fromEntityId: string;
    }) =>
      axios.post("policybucket/policy/duplicate", {
        policyBucketId,
        changeType,
        description,
        fromEntityId,
      }),
  });
}

export const dashboardUserListQuery = (args?: {
  page?: number;
  name: string;
  role: string;
}) =>
  queryOptions({
    queryKey: normalizeQueryKey(["dashboardUsers", args]),
    queryFn: async () => {
      return axios.get<FinBoxResponse<DashboardUsers>>("dashboardUsers", {
        params: {
          page_size: DASHBOARD_USERS_LIMIT,
          page_number: args?.page ?? 1,
          name: args?.name,
          role: args?.role,
        },
      });
    },
    staleTime: Infinity,
    placeholderData: keepPreviousData,
  });

export function useDashboardUsers({
  page,
  name,
  role,
}: {
  page?: number;
  name: string;
  role: string;
}) {
  return useQuery(dashboardUserListQuery({ page, name, role }));
}
